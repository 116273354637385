<template>
  <div>
    <ManageReview />
  </div>
</template>
<script>
import ManageReview from "@/components/ManageReview/ManageReview";
export default {
  components: {
    ManageReview,
  },
  created() {},
};
</script>
