<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          จัดการศิษย์เก่าที่ประสบความสำเร็จ
        </span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="#2AB3A3" dark
              >เพิ่มศิษย์เก่าที่ประสบความสำเร็จ</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersBrandTh"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
              <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
              <v-icon @click="DeleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      headersBrandTh: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        {
          text: "ชื่อ",
          value: "name_th",
          align: "start",
        },
        { text: "รุ่น", value: "generation", align: "start" },
        { text: "ระดับการศึกษา", value: "level.name_th", align: "start" },
        { text: "คณะที่เรียน", value: "facualty.name_th", align: "start" },
        { text: "สาขาวิชาที่เรียน", value: "major.name_th", align: "start" },
        { text: "งานที่ทำปัจจุบัน", value: "current_job_th", align: "start" },
        { text: "รูป", value: "img_url", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      items: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAll();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // const response = await this.axios.put(
      //   `${process.env.VUE_APP_API}/knowledges/updateIndex?news_type=ข่าวประชาสัมพันธ์`,
      //   val,
      //   auth
      // );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAll() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/successfulAlumnis`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAll", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItem(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/successfulAlumnis/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
    goToCreate() {
      this.$router.push("CreateReview");
    },
    UpdateNews(val) {
      localStorage.setItem("dataReview", Encode.encode(val));
      this.$router.push("EditReview");
    },
    ViewNews(val) {
      localStorage.setItem("dataReview", Encode.encode(val));
      this.$router.push("ViewReview");
    },
  },
};
</script>